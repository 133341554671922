import axios from "axios";

const state = {
  student: null,
  loading: false,
  error: null,
  students: [],
  careers: [],
  careers_loading: false,
  careers_error: null,
};

const mutations = {
  setLoading(state, payload) {
    state.loading = payload;
  },
  setStudent(state, payload) {
    state.student = payload;
  },
  setError(state, payload) {
    state.error = payload;
  },
  setStudents(state, payload) {
    state.students = payload;
  },
  setCareers(state, payload) {
    state.careers = payload;
  },
  addCareer(state, payload) {
    state.careers.push(payload);
  },
  setCareersLoading(state, payload) {
    state.careers_loading = payload;
  },
  setCareersError(state, payload) {
    state.careers_error = payload;
  },
};

const actions = {
  getStudent({ commit }, payload) {
    commit("setLoading", true);
    commit("setError", null);
    commit("setStudent", null);
    return axios
      .get("/programs/search-student.json", {
        params: {
          rut: payload,
        },
      })
      .then((response) => {
        commit("setStudent", response.data.student);
      })
      .catch((error) => {
        console.log(error);
        commit("setError", true);
      })
      .finally(() => {
                commit("setLoading", false);
            });
    },
    getStudents({commit}, payload) {
        commit("setLoading", true);
        commit("setError", null);
        commit("setStudents", []);
        return axios
            .get("/students.json", {
                params: {
                    rut: payload.rut,
                    identifier: payload.identifier,
                    nombres: payload.nombres,
                    paterno: payload.paterno,
                    materno: payload.materno,
        },
      })
      .then((response) => {
        commit("setStudents", response.data.students);
      })
      .catch((error) => {
        console.log(error);
        commit("setError", true);
      })
      .finally(() => {
        commit("setLoading", false);
      });
  },
  cleanStudent({ commit }) {
    commit("setStudent", null);
  },
  getCareers({ commit }, payload) {
    commit("setCareersLoading", true);
    commit("setCareersError", null);
    return axios
      .get("/students/careers/" + payload + ".json")
      .then((response) => {
        commit("addCareer", response.data.careers);
      })
      .catch((error) => {
        console.log(error);
        commit("setCareersError", true);
      })
      .finally(() => {
        commit("setCareersLoading", false);
      });
  },
};

export const studentStore = {
  namespaced: true,
  state,
  mutations,
  actions,
};
