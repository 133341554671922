
import axios from "axios";

const state = {
    selectTab: 0,
    checkedID: [],
    programSelected: []
};

const mutations = {
    setSelectTab(state, payload) {
        state.selectTab = payload;
    },
    setCheckedID(state, payload) {
        state.checkedID = payload;
    },
};



const actions = {
    
    

};

export const programStore = {
    namespaced: true,
    state,
    mutations,
    actions,
};