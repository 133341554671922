const state = {
  selectTab: 0,
  loading: false,
  subject: null,
  error: null,
  success: null,
};

const mutations = {
  setSelectTab(state, payload) {
    state.selectTab = payload;
  },
};

const actions = {
  getSubject({ commit }, payload) {
    commit('setLoading', true);
    commit('setError', null);
    commit('setSuccess', null);
    axios
      .get(`/subjects/${payload}`)
      .then((response) => {
        commit('setSubject', response.data.subject);
        
      })
      .catch((error) => {
        commit('setError', error.response.data.message);
        
      })
      .finally(() => {
        commit('setLoading', false);
      })
      ;
  }

};

export const subjectStore = {
  namespaced: true,
  state,
  mutations,
  actions,
};
