import Vue from 'vue/dist/vue.esm'
import Vuex from 'vuex'
import { studentStore } from './student'
import { programStore } from './program'
import { subjectStore } from './subject'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
      loading: false,
      
  },
  mutations: {

  },
  actions: {

  },
  modules: {
    studentStore,
    programStore,
    subjectStore
  }
})
